/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CampaignEditorState {
    showExitWarning: boolean;
    emailPreview: {
        showEmailPreviewModal: boolean;
        emailTo: string;
        previewAs: string;
        nodeData: any;
        submitLoading: boolean;
    },
    emailEditor: {
        value: any;
        actionTooltip: {
            show: boolean;
        }
        selectedConfig: string;
        selectedLineId: string;
    }
}

const initialState: CampaignEditorState = {
    showExitWarning: false,
    emailPreview: {
        showEmailPreviewModal: false,
        emailTo: "",
        previewAs: "",
        nodeData: null,
        submitLoading: false,
    },
    emailEditor: {
        value: [],
        actionTooltip: {
            show: false,
        },
        selectedConfig: "default",
        selectedLineId: "", 
    }
};

const campaignEditorSlice = createSlice({
    name: "campaignEditor",
    initialState,
    reducers: {
        setShowExitWarning(
            state,
            action: PayloadAction<{
                showWarning: boolean;
            }>
        ) {
            const {
                payload: { showWarning },
            } = action;
            state.showExitWarning = showWarning;
        },
        setShowEmailPreviewModal(
            state,
            action: PayloadAction<{
                showEmailPreviewModal: boolean;
            }>
        ) {
            const {
                payload: { showEmailPreviewModal },
            } = action;
            state.emailPreview.showEmailPreviewModal = showEmailPreviewModal;
        },
        setNodeData(
            state,
            action: PayloadAction<{
                nodeData: any;
            }>
        ) {
            const {
                payload: { nodeData },
            } = action;
            state.emailPreview.nodeData = nodeData;
        },
        setPreviewAs(
            state,
            action: PayloadAction<{
                previewAs: string;
            }>
        ) {
            const {
                payload: { previewAs },
            } = action;
            state.emailPreview.previewAs = previewAs;
        },
        setEmailTo(
            state,
            action: PayloadAction<{
                emailTo: string;
            }>
        ) {
            const {
                payload: { emailTo },
            } = action;
            state.emailPreview.emailTo = emailTo;
        },
        setEmailPreviewButtonLoading(
            state,
            action: PayloadAction<{
                submitLoading: boolean;
            }>
        ) {
            const {
                payload: { submitLoading },
            } = action;
            state.emailPreview.submitLoading = submitLoading;
        },
        setEmailEditorValue(
            state,
            action: PayloadAction<{
                value: any;
            }>
        ) {
            const {
                payload: {value },
            } = action;
            state.emailEditor.value = value;
        },
        showActionTooltip(
            state,
            action: PayloadAction<{
                show: boolean;
            }>
        ) {
            const {
                payload: { show },
            } = action;
            state.emailEditor.actionTooltip.show = show;
        },
        setSelectedConfig(
            state,
            action: PayloadAction<{
                selectedConfig: string;
            }>
        ) {
            const {
                payload: { selectedConfig },
            } = action;
            state.emailEditor.selectedConfig = selectedConfig;
        },
        setSelectedLineId(
            state,
            action: PayloadAction<{
                selectedLineId: string;
            }>
        ) {
            const {
                payload: { selectedLineId },
            } = action;
            state.emailEditor.selectedLineId = selectedLineId;
        }
    },
});

export const { 
    setShowExitWarning, 
    setShowEmailPreviewModal,
    setNodeData,
    setPreviewAs,
    setEmailTo,
    setEmailPreviewButtonLoading,
    setEmailEditorValue,
    showActionTooltip,
    setSelectedConfig,
    setSelectedLineId,
} = campaignEditorSlice.actions;
export default campaignEditorSlice.reducer;
